.services {
  padding: 0 3rem 0 3rem;
  display: flex;
  height: 30rem;
  margin-bottom: 8rem;
  margin-top: 9rem;
  margin-bottom: 13rem;
  /* scroll view */
  padding-top: 3rem;
}

/* left side */
.awesome {
  display: flex;
  flex-direction: column;
  position: relative;
  /* gap: 20px; */
}

.gradient-text {
  background-image: linear-gradient(45deg, #f3ec78, #af4261);
}

.awesome> :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}

.awesome> :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}

.awesome> :nth-child(3) {
  color: var(--gray);
  font-size: 14px;
  margin-top: 1rem;
}

.s-button {
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
}

/* right sided */
.cards {
  position: relative;
  pointer-events: none;
}

.cards>* {
  position: absolute;
}

/* blur */
.s-blur2 {
  left: 14rem;
  top: 8rem;
}

.s-blur1 {
  top: 13rem;
  left: -18rem;
}

@media screen and (max-width: 480px) {
  .services {
    margin-top: 0;
    flex-direction: column;
    gap: 5rem;
    height: 66rem;
    padding: 0;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 17rem;
  }

  .cards>* {
    position: static;
  }
}